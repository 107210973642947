.header-container {
  width: 100%;
  background: #eae6fd;
  display: flex;
  padding: 5% 10% 5% 15%;
}

.header__information {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header__location {
  margin-top: none;
}

.header__description {
  margin-top: 2%;
  width: 50%;
}
