.container-1100 {
  max-width: 1100px !important;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.header-1__background-color {
  background: #eae6fd;
}

.section-1__background-color {
  background: #fbfafc;
}

.section-2__background-color {
  background: #fff;
}
