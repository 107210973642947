.discipline-card {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
}
.discipline__title {
  font-weight: 500;
}
.discipline__salary {
  color: #407682;
}
.discipline__median-salary {
  color: #808594;
  font-weight: 500;
}
