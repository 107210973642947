.contribute-salary__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3rem;
  font-family: "Nunito", sans-serif;
}
.contribute-header-container {
  width: 100%;
  background: #eae6fd;
  padding-top: 3%;
  padding-bottom: 40px;
  font-family: "Inter";
  min-height: 400px;
}

.salary-form__container {
  margin-top: 0px;
  /* border: 2px solid #d0d0d0; */
  border-radius: 20px;
  width: 600px;
  min-height: 100px;
  padding: 20px;
  /* background: white; */
}
.form-header__container {
  background: white;
  border-radius: 30px;
  margin: 10px;
  padding: 20px;
  padding-left: 60px;
}
.form-section__container {
  background: white;
  border-radius: 30px;
  margin: 10px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  /* padding-right: 140px; */
}

.company-info__title {
  font-size: 24px;
}

.contribute-form__input {
  width: 40%;
  height: 2rem;
  outline: none;
  /* border: 1px solid #d0d0d0; */
  border: none;
  border-bottom: 1px solid #d0d0d0;

  /* border-radius: 5px; */
}

.required-asterisk {
  color: red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.submit-container {
  /* width: 100%; */
  /* padding-left: 50px; */
  /* justify-content: flex-end; */
  /* align-items: center; */
}

.submit-button {
  background: #5248b5;
  color: #eae6fd;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border: none;
  border-radius: 5px;
  /* color: white; */
}

/* MOBILE CSS */

@media screen and (max-width: 600px) {
  .contribute-salary__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3rem;
    font-family: "Nunito", sans-serif;
    /* min-width: 300px; */
  }
  .contribute-header-container {
    width: 100%;
    background: #eae6fd;
    padding-top: 3%;
    padding-bottom: 40px;
    font-family: "Inter";
    min-height: 250px;
  }
  .contribute-header__title {
    font-size: 36px;
  }
  .contribute-header__subtitle {
    font-size: 20px;
  }

  .salary-form__container {
    margin-top: 0px;
    /* border: 2px solid #d0d0d0; */
    border-radius: 20px;
    width: 400px;
    min-height: 100px;
    padding: 20px;
    /* background: white; */
  }
  .form-header__container {
    background: white;
    border-radius: 30px;
    margin: 10px;
    padding: 20px;
  }
  .form-section__container {
    background: white;
    border-radius: 30px;
    margin: 10px;
    padding: 30px;
    padding-right: 1.5rem;
  }

  .company-info__title {
    font-size: 24px;
  }

  .contribute-form__input {
    width: 40%;
    height: 2rem;
    outline: none;
    /* border: 1px solid #d0d0d0; */
    border: none;
    border-bottom: 1px solid #d0d0d0;

    /* border-radius: 5px; */
  }

  .required-asterisk {
    color: red;
  }
  .submit-container {
    justify-content: center;
    align-items: center;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .submit-button {
    background: #5248b5;
    color: #eae6fd;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border: none;
    border-radius: 5px;
    margin: auto;
  }
}
