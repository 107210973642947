.header-container {
  width: 100%;
  background: #eae6fd;
  padding-top: 3%;
  padding-bottom: 50px;
  /* font-family: "Nunito", sans-serif; */
  font-family: "Inter";
  justify-content: left;
}

.header__information {
  /* width: 60%; */
  /* display: flex; */
  flex-direction: column;
}

.header__averages {
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.header__average {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 30%;
}

.average__bar {
  width: 60%;
  margin-bottom: 10px;
  /* margin: 0 auto; */
  height: 5px;
  background: red;
  border: none;
  border-radius: 10px;
}

.average__amount {
  font-weight: 700;
  font-size: 14px;
}

.average__percentile {
  font-weight: 700;
  font-size: 14px;
  color: #5248b5;
}
.header__buttons {
  width: 70%;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.button__contribute {
  outline: none;
  border: 1px solid #5248b5;
  color: #5248b5;
  width: 250px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 10px;
  /* padding: 0px 60px 0px 60px; */
  background: none;
  height: 40px;
}

.button__jobs {
  outline: none;
  height: 40px;
  width: 250px;
  border: 1px solid #5248b5;
  color: white;
  font-size: 14px;
  font-weight: 800;
  border-radius: 10px;
  /* padding: 0px 60px 0px 60px; */
  background: #5248b5;
}
.median-salary__container {
  margin: 0px;
  display: flex;
  flex-direction: column;
}

.median-salary__title {
  margin-top: 20px;
  font-size: 36px;
  font-weight: 700;
  color: #5248b5;
  letter-spacing: none;
}

.header__salary {
  font-size: 30px;
  font-weight: 700;
  color: black;
  letter-spacing: none;
}

/* Color Options */
.white {
  background-color: white;
  color: white;
}

.light {
  background-color: #cdc6ff;
  color: #cdc6ff;
}

.dark {
  background-color: #5248b5;
  color: #5248b5;
}
